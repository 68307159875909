@use './lib' as lib;

$_theme: (
   'popup-border': none,
   'popup-bgColor': #ffffff,
   'popup-boxShadow': 0 0 10px rgb(0 0 0 / 30%),
   'popup-textColor': #292929,
   'popup-heading-textColor': #292929,
   'popup-zIndex': 9999,
);

.lnc-firstRunPopup {
   box-sizing: border-box;
   position: fixed;
   left: 0;
   right: 0;
   bottom: 0;
   max-height: 95vh;
   overflow-y: auto;
   padding: 20px;
   @include lib.useVar('z-index', 'popup-zIndex', $_theme);
   @include lib.useVar('border', 'popup-border', $_theme);
   @include lib.useVar('color', 'popup-textColor', $_theme);
   @include lib.useVar('background-color', 'popup-bgColor', $_theme);
   @include lib.useVar('box-shadow', 'popup-boxShadow', $_theme);
   &-header {
      @include lib.useVar('color', 'popup-heading-textColor', $_theme);
   }
   &-content {
      > :first-child {
         margin-top: 0;
      }
   }
   // We only want to constrain the width of the popup content when the content appears
   // within the first-run popup. If consumers of the LNC library override the popup's
   // chrome/container, we will only render the .lnc-firstRunPopup-content element for
   // them. Since they will supply the chrome/container, we leave constraining the width
   // up to them.
   // See: docs/advanced-use-cases.html#overriding-default-ui-components
   & &-content { // .lnc-firstRunPopup .lnc-firstRunPopup-content
      max-width: 1140px;
      margin: 0 auto;
   }
}
