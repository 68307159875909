.lnc-settings-content {
   > :first-child {
      margin-top: 0;
   }
}

.lnc-setting-label {
   font-size: inherit;
   font-weight: 700;
}
