@use './lib' as lib;

$_theme: (
   // Overlay
   'modal-overlay-bgColor': rgba(0, 0, 0, 0.5),
   'modal-overlay-zIndex': 9999,
   'modal-border': none,
   // Header
   'modal-header-textColor': #ffffff,
   'modal-header-bgColor': #757575,
   // Header - close button
   'modal-header-closeButton-textColor': #ffffff,
   'modal-header-closeButton-bgColor': rgba(18, 18, 18, 0.2),
   // Header - close button - hover
   'modal-header-closeButton-textColor--hover': #ffffff,
   'modal-header-closeButton-bgColor--hover': rgba(18, 18, 18, 0.5),
   // Body
   'modal-body-textColor': #292929,
   'modal-body-bgColor': #ffffff,
   'modal-boxShadow': 0 0 10px 3px rgb(0, 0, 0, 0.5),
);

.lnc-modal {
   display: flex;
   flex-direction: column;
   overflow: hidden;
   box-sizing: border-box;
   min-width: 200px;
   max-width: 700px;
   // It's tempting to use `vh` units here, but on iOS Chrome/Safari `vh` units represent
   // the largest viewport height that the browser will have. For example, as you scroll a
   // page down in Chrome and Safari, the bottom row of buttons in the browser's chrome is
   // hidden and the address bar shrinks in size slightly, effectively making the viewport
   // height larger. This is the height that the `vh` unit is based on. When you scroll
   // back up, the bottom button bar comes back into view and the address bar grows
   // larger, but the `vh` unit stays the same. So, if you set the height of the modal to
   // `90vh`, the modal will extend outside of the viewable area when the address bar and
   // bottom button row is showing because `vh` is based on the larger viewport height.
   max-height: 90%;
   @include lib.useVar('box-shadow', 'modal-boxShadow', $_theme);
   @include lib.useVar('border', 'modal-border', $_theme);
   &-overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      @include lib.useVar('background-color', 'modal-overlay-bgColor', $_theme);
      @include lib.useVar('z-index', 'modal-overlay-zIndex', $_theme);
      padding: 0 10px;
   }
   &-header {
      display: flex;
      justify-content: space-between;
      flex: 0 0 auto; // Assists older browsers to calculate size correctly
      align-items: center;
      box-sizing: border-box;
      @include lib.useVar('background-color', 'modal-header-bgColor', $_theme);
      @include lib.useVar('color', 'modal-header-textColor', $_theme);
      &-closeButton {
         display: inline-flex;
         justify-content: center;
         align-items: center;
         @include lib.useVar('background-color', 'modal-header-closeButton-bgColor', $_theme);
         @include lib.useVar('color', 'modal-header-closeButton-textColor', $_theme);
         width: 50px;
         height: 50px;
         appearance: none;
         border: none;
         &-icon {
            display: block;
            width: 24px;
            height: 24px;
         }
         &:hover,
         &:focus {
            @include lib.useVar('background-color', 'modal-header-closeButton-bgColor--hover', $_theme);
            @include lib.useVar('color', 'modal-header-closeButton-textColor--hover', $_theme);
         }
      }
      &-heading {
         font-size: 1em;
         padding: 0 20px;
         margin: 0;
         outline: none;
      }
   }
   &-body {
      @include lib.useVar('background-color', 'modal-body-bgColor', $_theme);
      @include lib.useVar('color', 'modal-body-textColor', $_theme);
      padding: 20px;
      flex: 1 1 auto; // Assists older browsers to calculate size correctly
      box-sizing: border-box;
      overflow-y: scroll;
   }
}
