@use './lib' as lib;

$_theme: (
   // ----------------------------------------
   // Primary Button
   // ----------------------------------------
   //
   // - Default state
   'primaryButton-textColor': #ffffff,
   'primaryButton-borderColor': rgba(18, 18, 18, 0.1),
   'primaryButton-borderColor-bottom': rgba(18, 18, 18, 0.1),
   'primaryButton-borderWidth': 1px,
   'primaryButton-bgColor': #4a6da7,
   // - Hover state
   'primaryButton-borderColor--hover': rgba(18, 18, 18, 0.1),
   'primaryButton-borderColor-bottom--hover': rgba(18, 18, 18, 0.1),
   'primaryButton-bgColor--hover': #345996,
   // - Focus state
   'primaryButton-bgColor--focus': #345996,
   'primaryButton-boxShadow--focus': 0 0 5px 1px #7f9fd3,
   //
   // ----------------------------------------
   // Secondary Button
   // ----------------------------------------
   //
   // - Default state
   'secondaryButton-textColor': #4a6da7,
   'secondaryButton-borderColor': rgba(18, 18, 18, 0.1),
   'secondaryButton-borderColor-bottom': rgba(18, 18, 18, 0.1),
   'secondaryButton-borderWidth': 1px,
   'secondaryButton-bgColor': transparent,
   // - Hover state
   'secondaryButton-borderColor--hover': rgba(18, 18, 18, 0.1),
   'secondaryButton-borderColor-bottom--hover': rgba(18, 18, 18, 0.1),
   'secondaryButton-bgColor--hover': #e8effb,
   // - Focus state
   'secondaryButton-bgColor--focus': #e8effb,
   'secondaryButton-boxShadow--focus': 0 0 5px 1px #7f9fd3,
);

@mixin _styleButtonType($buttonType) {
   @include lib.useVar('background-color', $buttonType + 'Button-bgColor', $_theme);
   @include lib.useVar('color', $buttonType + 'Button-textColor', $_theme);
   @include lib.useVar('border-width', $buttonType + 'Button-borderWidth', $_theme);
   @include lib.useVar('border-color', $buttonType + 'Button-borderColor', $_theme);
   @include lib.useVar('border-bottom-color', $buttonType + 'Button-borderColor-bottom', $_theme);
   &:hover {
      @include lib.useVar('background-color', $buttonType + 'Button-bgColor--hover', $_theme);
      @include lib.useVar('border-color', $buttonType + 'Button-borderColor--hover', $_theme);
      @include lib.useVar('border-bottom-color', $buttonType + 'Button-borderColor-bottom--hover', $_theme);
   }
   &:focus {
      @include lib.useVar('background-color', $buttonType + 'Button-bgColor--focus', $_theme);
      @include lib.useVar('box-shadow', $buttonType + 'Button-boxShadow--focus', $_theme);
   }
}

.lnc-button {
   border-style: solid;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   vertical-align: middle;
   padding: 0.5em;
   &:hover {
      cursor: pointer;
      text-decoration: none;
   }
   &:focus {
      outline: 0;
   }
   &--primary {
      @include _styleButtonType('primary');
   }
   &--secondary {
      @include _styleButtonType('secondary');
   }
}

.lnc-actionButtons {
   .lnc-button {
      margin-top: 0.5em;
      @include lib.useDirection('ltr') {
         margin-right: 6px;
      }
      @include lib.useDirection('rtl') {
         margin-left: 6px;
      }
      &:last-child {
         @include lib.useDirection('ltr') {
            margin-right: 0;
         }
         @include lib.useDirection('rtl') {
            margin-left: 0;
         }
      }
   }
}
