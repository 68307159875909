/// Helper mixin for writing direction-specific styling. Call the mixin with either 'ltr'
/// or 'rtl' to write CSS that only applies to that language direction.
///
/// @param {string} $dir - Either 'ltr' or 'rtl'
@mixin useDirection($dir) {
   // We use the [dir='ltr|rtl'] attribute here instead of the direction CSS class because
   // clients can override all of the language-related classes, including the direction
   // class.
   .lnc-languageBoundary[dir='#{$dir}'] & {
      @content;
   }
}
