@use './lib' as lib;

$_theme: (
   // Equivilant to gray-300 https://codepen.io/sosuke/pen/Pjoqqp
   'loadingIndicator-filter': invert(65%) sepia(0%) saturate(0%) hue-rotate(84deg) brightness(101%) contrast(97%),
);

.lnc-loadingIndicator {
   background-image: url(../icons/circle-open.svg?inline);
   @include lib.useVar('filter', 'loadingIndicator-filter', $_theme);
   width: 80px;
   height: 80px;
   animation: lnc-rotating 1s linear infinite;
   margin: 0 auto;
}

@keyframes lnc-rotating {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}
