@use './lib' as lib;

$_theme: (
   'sectionSeparator-color': #a7a7a7,
);

.lnc-sectionSeparator {
   padding-bottom: 0.25em;
   margin-bottom: 0.75em;
   border-bottom-width: 1px;
   border-bottom-style: solid;
   @include lib.useVar('border-bottom-color', 'sectionSeparator-color', $_theme);
}
