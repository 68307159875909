// When you add a new transition to this file, add a new transition to
// src/js/lib/Transitions.ts

// Transition: fade-in
[class*=lnc-transition--fadeIn] {
   transition: opacity 200ms ease-in-out;
}

.lnc-transition--fadeIn-start {
   opacity: 0;
}

.lnc-transition--fadeIn-end {
   opacity: 1;
}

// Transition: fade-out
[class*=lnc-transition--fadeOut] {
   transition: opacity 200ms ease-in-out;
}

.lnc-transition--fadeOut-start {
   opacity: 1;
}

.lnc-transition--fadeOut-end {
   opacity: 0;
}

// Transition: slideUp
[class*=lnc-transition--slideUp] {
   transition: transform 600ms ease-in-out;
}

.lnc-transition--slideUp-start {
   transform: translateY(150%);
}

.lnc-transition--slideUp-end {
   transform: translateY(0%);
}

// Transition: slideDown
[class*=lnc-transition--slideDown] {
   transition: transform 400ms ease-in-out;
}

.lnc-transition--slideDown-start {
   transform: translateY(0%);
}

.lnc-transition--slideDown-end {
   transform: translateY(150%);
}
