@use './lib' as lib;

$_theme: (
   // Checkbox
   // - Default state
   'checkbox-borderColor': #757575,
   'checkbox-borderWidth': 1px,
   'checkbox-bgColor': #ffffff,
   'checkbox-checkMarkColor': #345996,
   // - Hover state
   'checkbox-borderColor--hover': #757575,
   'checkbox-bgColor--hover': #ffffff,
   // - Checked state
   'checkbox-borderColor--checked': #345996,
   'checkbox-borderWidth--checked': 2px,
   'checkbox-bgColor--checked': #e8effb,
   // - Focus state
   'checkbox-boxShadow--focus': 0 0 4px #7f9fd3,
   // - Disabled state
   'checkbox-borderColor--disabled': #d8d8d8,
   'checkbox-bgColor--disabled': #f1f1f1,
   'checkbox-checkMarkColor--disabled': #d8d8d8,
   // - Disabled and checked state
   'checkbox-borderWidth--disabled-checked': 1px,
);

.lnc-checkbox {
   display: flex;
   flex-wrap: wrap;
   input[type='checkbox'] {
      width: 24px;
      height: 24px;
      // Add top margin to approx. align with first line of label in English
      margin-top: 0.25em;
      vertical-align: middle;
      position: relative;
      appearance: none;
      cursor: pointer;
      border-radius: 4px;
      @include lib.useVar('border-color', 'checkbox-borderColor', $_theme);
      @include lib.useVar('border-width', 'checkbox-borderWidth', $_theme);
      border-style: solid;
      &:focus {
         outline: 0;
         @include lib.useVar('box-shadow', 'checkbox-boxShadow--focus', $_theme);
      }
      &:checked {
         @include lib.useVar('border-color', 'checkbox-borderColor--checked', $_theme);
         @include lib.useVar('border-width', 'checkbox-borderWidth--checked', $_theme);
         @include lib.useVar('background-color', 'checkbox-bgColor--checked', $_theme);
         &::before {
            position: absolute;
            display: block;
            content: ' ';
            @include lib.useVar('background-color', 'checkbox-checkMarkColor', $_theme);
            // Using explicit font size here to ensure the "check" icon always
            // fits in its container.
            height: 100%;
            width: 100%;
            top: 1px;
            mask: url(../icons/check.svg?inline) no-repeat 50% 50%;
         }
      }
      &[disabled] {
         @include lib.useVar('border-color', 'checkbox-borderColor--disabled', $_theme);
         @include lib.useVar('background-color', 'checkbox-bgColor--disabled', $_theme);
         &:hover,
         & + label:hover {
            cursor: not-allowed;
         }
         // Both checked and disabled
         &:checked {
            @include lib.useVar('border-width', 'checkbox-borderWidth--disabled-checked', $_theme);
         }
         &::before {
            @include lib.useVar('background-color', 'checkbox-checkMarkColor--disabled', $_theme);
         }
      }
      & + label {
         cursor: pointer;
         flex: 1;
         margin-top: 4px;
         margin-bottom: 0;
         font-weight: 700;
         @include lib.useDirection('ltr') {
            margin-left: 6px;
         }
         @include lib.useDirection('rtl') {
            margin-right: 6px;
         }
      }
   }
}
