@use 'sass:map' as map;

/// A helper for declaring CSS properties that reference a CSS custom property
/// using a `var` statement. We always define `var` statements with a fallback
/// value so that our
/// [postcss-css-var-fallback](https://github.com/silvermine/postcss-css-var-fallback)
/// plugin can add fallback statements for browsers that don't support CSS custom
/// properties.
///
/// This mixin requires that you pass it a `$themeMap` whose keys are the CSS custom
/// property names and whose values are the default property values. It will prefix all
/// custom property names with "lnc".
///
/// @example scss
///    $theme: ('modal-bgColor': rgba(0, 0, 0, 0.3));
///
///    .lnc-modal {
///       @include lib.useVar('color', 'modal-bgColor', $theme);
///       // Outputs: color: var(--lnc-modal-bgColor, rgba(0, 0, 0, 0.3));
///    }
///
/// @param {string} $cssPropName - A CSS property name, like `background-color`
/// @param {string} $varName - The name of the CSS custom property (minus the "lnc"
/// prefix). This should also be the key for the corresponding property value in the
/// `$themeMap`.
/// @param {map} $themeMap - A map whose keys are CSS property names and whose values are
/// the default value you want the CSS property to have.
@mixin useVar($cssPropName, $varName, $themeMap) {
   @if (not map.has-key($themeMap, $varName)) {
      @error 'The $themeMap passed to the useVar mixin does not contain the key: #{$varName}';
   }
   #{$cssPropName}: var(--lnc-#{$varName}, #{map.get($themeMap, $varName)});
}
